import React from 'react';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import { androidURLFacilities, iosURLFacilities } from '../../constants/urls';

function DownloadHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-download-area pt-150 pb-160 mb-90 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="appie-download-content">
                                <span>Download Supermedic</span>
                                <h3 className="title">
                                Endless of opportunities await, <br />
                                    start now
                                </h3>
                                <p>
                                Post now and get top-notch applicants at your fingertips, start now. Discover highly qualified and vetted doctors in no time.

                                </p>
                                <ul>
                                    <li>
                                        <a href={iosURLFacilities}>
                                            <i className="fab fa-apple" />
                                            <span>
                                                Download for <span>iOS</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href={androidURLFacilities}>
                                            <i className="fab fa-google-play" />
                                            <span>
                                                Download for <span>Android</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="download-shape-1">
                    <img src={shape15} alt="" />
                </div>
                <div className="download-shape-2">
                    <img src={shape14} alt="" />
                </div>
                <div className="download-shape-3">
                    <img src={shape13} alt="" />
                </div>
            </section>
        </>
    );
}

export default DownloadHomeTwo;
