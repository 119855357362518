import React from 'react';
import thumb from '../../assets/images/supermedic-logo.png';

function DetailsService() {
    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">Southeast Asia’s healthcare backbone </h3>
                                    <p>
                                    Welcome to Supermedic, the premier marketplace for healthcare work in Southeast Asia. Our platform simplifies the process of searching and hiring for locum shifts, connecting healthcare facilities with talented healthcare professionals looking for work on their own terms. At Supermedic, we understand that every healthcare professional is unique, with their own individual lifestyle and career goals. That's why we empower our professionals to choose from a large pool of jobs suited to their specific needs. By using our platform, healthcare facilities gain access to thousands of highly skilled and qualified healthcare professionals, receiving multiple applicants whenever and wherever they want. Join Supermedic today and experience the ease and convenience of finding the perfect healthcare work opportunities for you.
                                    </p>
                                    <span> </span>
                                    <h3 className="title">Core Values</h3>
                                    <span>
                                    Partners’ first 
                                    </span>
                                    <p>
                                        At Supermedic, we prioritize our partners by involving them every step of the way to ensure that our products and services meet their needs and exceed their expectations.
                                    </p>
                                    <span>
                                    Fairness and discretion for all 
                                    </span>
                                    <p>
                                    We value fairness and discretion for all parties involved. Supermedic only shares healthcare professionals’ full names and contact information after a successful shift booking, and we provide cancellation coverage based on circumstances to ensure fairness for both parties.                                    </p>
                                    <span>
                                    Deep ties to healthcare and technology  
                                    </span>
                                    <p>
                                    Supermedic is built and operated by healthcare veterans with over 30 years of experience and Technology folks with a nack for designing lovable digital experiences.                                     </p>
                                    <span>
                                    Innovation meets passion.
                                    </span>
                                    <p>
                                    At Supermedic, we are passionate about innovation and continuously seeking out new technologies and industry trends to add value to the lives of those we serve.                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
