import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import DownloadHomeTwo from './DownloadHomeTwo';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ServicesHomeOne from './ServicesHomeOne';
import ServicesHomeTwo from './ServicesHomeTwo';
import TestimonialHomeOne from './TestimonialHomeOne';
import WorkPartHomeEight from './WorkPartHomeEight';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeOne />
            <ServicesHomeOne />
            {/* <FeaturesHomeOne /> */}
            <ServicesHomeTwo className="pt-90" />
            {/* <VideoPlayerHomeTwo /> */}
            <WorkPartHomeEight />
            <TestimonialHomeOne />
            <DownloadHomeTwo className="mb-0" />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
