import React from 'react';
import IconSix from '../../assets/images/icons/clinic/landing/10.png';
import IconOne from '../../assets/images/icons/clinic/landing/5.png';
import IconThree from '../../assets/images/icons/clinic/landing/7.png';
import IconFour from '../../assets/images/icons/clinic/landing/8.png';
import IconFive from '../../assets/images/icons/clinic/landing/9.png';
function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Why use Supermedic</h3>
                                <p>
                                Hire with confidence, choose from a large pool of qualified applicants.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                <img src={IconOne} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Multi candidate option</h4>
                                <p>
                                Get numerous applicants per job posting and select either one or shortlist multiple candidates.
                                </p>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                <img src={IconTwo} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Immediate shift coverage</h4>
                                <p>
                                Stay protected - our 100% money-back guarantee means you'll never lose money if a Supermedic provider cancels at the last minute.
                                </p>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                <img src={IconThree} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Urgent shift </h4>
                                <p>
                                Need to fill shifts fast? No problem - Post vacancies last minute and have our Supermedic providers notified in a flash!
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                <img src={IconFour} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Zero hassle-listing </h4>
                                <p>
                                With us, posting vacancies takes mere seconds- and completely free of charge for your facility. 
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                <img src={IconFive} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">With you at every step</h4>
                                <p>
                                Stay updated throughout the process, from shift posting to completion. Receive timely notifications.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                <img src={IconSix} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Post engagement</h4>
                                <p>
                                Stay informed about post performance. Supermedic strives to ensure every shift is filled, no matter the urgency.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
