import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/supermedic-facilities-logo.png';


function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>Supermedic Facilities</p>
                         
                             
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Support</h4>
                                <ul>
                                    <li>
                                        <a href="https://docs.google.com/document/d/e/2PACX-1vSo1kguu2aumjHm1IwT-hT_nDz3G70SHARIWF2PhOfFG5_abMJQqBkKZTh0BctMAg/pub?embedded=true">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="https://docs.google.com/document/d/e/2PACX-1vR7TPQsU-svthrBmcWp0-TFEkVBdcJIBN7ICZoiAI3onDsMbhLprh9exxvMMn3npg/pub?embedded=true">Terms & Conditions</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:hello@supermedic.health">
                                            <i style={{marginTop: 4}} className="fal fa-envelope" /> hello@supermedic.health
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="https://apps.apple.com/app/supermedic-facilities/id6443664368">
                                                <i className="fab fa-apple" /> Download for iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="https://play.google.com/store/apps/details?id=com.supermedic.facilities">
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2023 Supermedic. All rights reserved.</p>
                                    <p style={{fontSize: 9}}>
                                    Image by <a href="https://www.freepik.com/free-vector/health-professional-team_7477375.htm#query=medical%20staff&position=3&from_view=search&track=ais">Freepik</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
