import React from 'react';
import IconSix from '../../assets/images/icons/doctors/landing/10.png';
import IconOne from '../../assets/images/icons/doctors/landing/5.png';
import IconTwo from '../../assets/images/icons/doctors/landing/6.png';
import IconThree from '../../assets/images/icons/doctors/landing/7.png';
import IconFive from '../../assets/images/icons/doctors/landing/9.png';


function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Why use Supermedic</h3>
                                <p>
                                Built to empower healthcare professionals to  find the best work at anywhere, everywhere.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={IconOne} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Next-day pay</h4>
                                <p>
                                Complete your shift, get money into your account the very next day!
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                <img src={IconTwo} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Urgent shift </h4>
                                <p>
                                Get instant alerts for top-paying urgent shifts and maximize your earnings!
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                <img src={IconThree} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Flexibility</h4>
                                <p>
                                Search for jobs based on your availability, clinical expertise, desired rate and location!
                                </p>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                <img src={IconFour} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Immediate shift coverage</h4>
                                <p>
                                Get paid what you deserve - even when facilities cancel at the last minute! Supermedic provides last minute cancellation compensation. 
                                </p>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                <img src={IconFive} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">Your information </h4>
                                <p>
                                We respect your privacy. Facilities receive only essential info (name, contact) after a booking is confirmed. No personal documents are shared.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                <img src={IconSix} alt="" style={{height: 50}} />
                                </div>
                                <h4 className="title">With you at every step</h4>
                                <p>
                                Stay updated throughout the process, from application to completion. Receive timely notifications.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
