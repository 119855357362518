import React from 'react';
import IconOne from '../../assets/images/icons/clinic/landing/1.png';
import IconTwo from '../../assets/images/icons/clinic/landing/2.png';
import IconThree from '../../assets/images/icons/clinic/landing/3.png';
function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                            The Supermedic Way
                            </h3>
                            <p>Hire with confidence, choose from a pool of high quality professionals with supermedic.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                            </div>
                            <h4 className="appie-title">Start now, fill fast</h4>
                            <p>Post a shift, review candidates, fill shift, repeat</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">Huge talent pool</h4>
                            <p>Receive applicants highly skilled and reliable locum doctors.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                            </div>
                            <h4 className="appie-title">With you at every step</h4>
                            <p>Get notified from when you post to when the shift gets completed, all in one platform.</p>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={IconFour} alt="" />
                            </div>
                            <h4 className="appie-title">Stay protected with us </h4>
                            <p>Get reimbursed for last minute cancellations with Supermedic. </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
