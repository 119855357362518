import React from "react";

function Forms() {
  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact--info-area">
                <h3>Get in touch</h3>
                <p>
                  Looking for help? Fill the form and start a new adventure.
                </p>
                <div className="single-info">
                  <h5>Headquarters</h5>
                  <p>
                    <i className="fal fa-home"></i>
                    Kuala Lumpur
                    <br /> Malaysia
                  </p>
                </div>
                <div className="single-info">
                  <h5>Support</h5>
                  <p>
                    <i className="fal fa-envelope"></i>
                    hello@supermedic.health
                  </p>
                </div>
                <div className="ab-social">
                  <h5>Follow Us</h5>
                  <a className="fac" href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="twi" href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="you" href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a className="lin" href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div
                dangerouslySetInnerHTML={{
                  __html: `                        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script><iframe title="contactForm" class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/appCEPj5wFw8KeFoS/shryWHocRKd2mLaoJ?backgroundColor=cyan" frameborder="0" onmousewheel="" width="100%" height="798" style="background: transparent; border: 1px solid #ccc;"></iframe>
                            `,
                }}
              />
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Forms;
